import React, { Component } from "react";
import "../../../css/partner-with-us.css";
import STRAWBERRY_IMG from "../../../images/strawberry.svg";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import sticker2 from "../../../images/c4-sticker2.png";
import c7Pattern from "../../../images/c7-pattern.svg";

class HeroSection extends Component {
  render() {
    const { data, assets } = this.props;
    if (!data) return null;
    return (
      <Container fluid className="c4 c-pad hero_container">
        <div className="container-lg">
          <Row className="hero_content_root">
            <Col className="col-lg-6 col-12">
              <div className="grow_with_us_btn_container">
                <button className="grow_with_us_btn d-flex flex-row align-items-center">
                  <p>Grow with us</p>
                  <img
                    src={STRAWBERRY_IMG}
                    className="h_24 w_24"
                    alt="Strawberry"
                  />
                </button>
              </div>

              <h1 className="hero_title">{data.label}</h1>
              <p className="hero_desc">{data.desc}</p>
            </Col>

            <Col
              className="col-lg-5 col-12"
              style={{ alignItems: "center", position: "relative" }}
            >
              <Carousel
                indicators={data.banner?.length > 1}
                prevIcon={false}
                prevLabel={"false"}
                nextIcon={false}
                nextLabel={"false"}
                interval={3000}
                className="hero_carousel"
                slide={true}
              >
                {data.banner?.map((_, i) => (
                  <Carousel.Item className="carousel_shadow" key={i}>
                    <img
                      src={assets?.[_.img]?.url}
                      className="hero_carousel_img"
                      alt={_.title}
                    />
                    <div className="hero_carousel_info">
                      <h1>{_.title}</h1>
                      <p>{_.desc}</p>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              <img
                alt=""
                className="c4-sticker2"
                src={sticker2}
                height="64px"
                width="auto"
                style={{
                  top: -30,
                  zIndex: 1,
                  left: -120,
                }}
              />
              <img
                alt=""
                className="c4-sticker2"
                src={c7Pattern}
                height="64px"
                width="auto"
                style={{
                  top: 40,
                  zIndex: 1,
                  right: -40,
                  height: "300px",
                  width: "150px",
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default HeroSection;
